#main-navigation{
    background-color: $king-blue;
    position: fixed;
    z-index: 9;
    top:0;
    width:100%;
    padding: 8px 15px;

    .messe-label{
        max-width: 172px;
        
        div{
            width: 170px;
            height: 25px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position:center;
        }
        .fk-logo{
            background-image: url('../../htdocs/img/logo/logo_fachkraefte.png');
        }
        .starter-logo{
            background-image: url('../../htdocs/img/logo/logo_starter_3.jpg');
            border: 1px solid $white;
        }
    }
    .menu{
        padding:13px 0;
    }
    #burger{
        padding:13px 0;
        display: none;
        text-align: right;
        width: 100%;        
        img{
            width: 40px;
        }
    }
    @include breakpoint(1024px down){
        .menu{
            display:none;
        }
        #burger{
            display:block;
        }
    }
    .logo-wrapper{
        img{
            max-width: 172px;
            max-height: 45px;
        }
    }
    ul{
        li{
            a{
                font-family: 'GothamCondensedBold';
                text-transform: uppercase;
                color:white;
                font-size: 20px;
                font-family: $font-monobold;
                &.active{
                    color:$red;
                }
            }
        }
    }
}