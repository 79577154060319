@charset 'utf-8';

/* HELPER FUNCTIONS */
@import './helper/functions';
@import './settings/reset';
@include reset();
@import './helper/rem-calc';
@import './helper/space';

/* SETTINGS */
$global-width: rem-calc(1080) !default;
@import './settings/grid';
@import './settings/variables';
@import './settings/fonts';
@import './helper/position';

/* LAYOUT */
@import './components/navigation';
@import './components/header-image';
@import './components/countdown';
@import './components/form';
@import './components/sponsoren';
@import './components/ablauf';
@import './components/angebote';
@import './components/statement';
@import './components/aussteller';
@import './components/timetable';
@import './components/auswahl';
@import './components/stoerer';
@import './components/recruiting-video';

/* LAYOUT */
@import './site-layout/base';

/* VENDOR */
@import './vendor/klaro';