.header-image-wrapper{

    position: relative;
    margin-top:85px;

    @include breakpoint(small down){
        margin-top:75px;
    } 

    .header-image{  
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: bottom;
        margin-bottom: 85vh;
    }

    .header-content-box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 85vh;

        .date-image{
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            // max-width: 625px;
            width: 100%;
            height: 530px;
            top: 20%;
            left: 0;         
            @include breakpoint(medium down){
                max-width: 680px;
            } 
            @include breakpoint(small down){
                //height: 125px;
                top:30px;
            }   
        }
        
        .link-image{
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            max-width: 500px;
            width: 100%;
            height: 45px;
            top: calc(50% + 210px);
            right: 35px;  
            background-position: right;    

            @include breakpoint(medium down){
                max-width: 430px;
            } 

            @include breakpoint(small down){
                width: 85%;
                right: initial;
                left: 50%;
                background-position: 100%;
                transform: translateX(-50%);
                background-position: center;
            }   
           
        }        
        
        @include breakpoint(small down){
            padding: 10px;
            
        }

    }

    &.fk-messe{
        .header-image{
            background-image: url('../../htdocs/img/content/fk_header_bild.jpg');
        }
        .date-image{
            background-image: url('../../htdocs/img/content/fk_header_datum.png');
        }
        .link-image-voranmeldung{
            background-image: url('../../htdocs/img/content/fk_header_verlinkung_voranmeldung.png');
        }
        .link-image-aussteller{
            background-image: url('../../htdocs/img/content/fk_header_verlinkung_aussteller.png');
            @include breakpoint(medium down){
                top: 90%;
            } 
            
            @media only screen and (max-width: 320px) {
                top: 85%;
            }
        }
        .link-image-messe{
            background-image: url('../../htdocs/img/content/fk_header_verlinkung_fachkraefte.png');
            top: calc(50% + 270px);
            @include breakpoint(medium down){
                top: 84%;
            } 
            @media only screen and (max-width: 320px) {
                top: 75%;
            }
        }
    }
    &.starter-messe{
        .header-image{
            background-image: url('../../htdocs/img/content/headerbild_2022.jpg');
            @include breakpoint(small down){
                background-position-x: 70%;
            }  
        }
        .date-image{
            background-image: url('../../htdocs/img/content/dates_03.png');
        }
        .link-image-voranmeldung{
            background-image: url('../../htdocs/img/content/s_header_verlinkung_voranmeldung.png');
        }
        .link-image-aussteller{
            background-image: url('../../htdocs/img/content/s_header_verlinkung_aussteller.png');
            
            @include breakpoint(medium down){
                top: 90%;
            }

            @include breakpoint(small down){
                top: 79%;
                width: 70%;
            } 
        }
        .link-image-messe{
            background-image: url('../../htdocs/img/content/s_header_verlinkung_schueler.png');
            top: calc(50% + 270px);
            @include breakpoint(medium down){
                top: 88%;
            } 
            @include breakpoint(small down){
                width: 70%;
            } 
            @media only screen and (max-width: 320px) {
                
                top: 75%;
            }
        }
    }
    
}

