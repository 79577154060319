// COLORS
$blue:#0e19a9;
$blue-dark: #0e1977;
$blue-mid: #0e19e6;
$blue-light: #0e5cff;

$green:#17ca48;
$green-dark: #008b00;
$green-mid: #00ae00;
$green-light: #20d700;

$red: #DA2128;
$success-green: #067b4b;
$natty-green:#11C943;

$button-bg:#586777;
$dark-grey:#313131;
$grey:#F4F4F4;

$dark-blue: $blue-dark;
$king-blue: $blue;
$other-blue: $blue-mid;
$light-blue: #e2edf3;
$white: white;

// FONTS
$font-regular: "Roboto";
$font-mono: "Roboto-Mono";
$font-bold: "Roboto-Bold";
$font-monobold: "Roboto-Mono-Bold";