@mixin box-sizing ($box: border-box) {
    $valid: true;
    @if type-of($box) != "string" {
      @warn "box: #{$box} is not a string";
      $valid: false;
    }
    @if $valid == true {
      @include prefixer(box-sizing, $box, webkit moz);
    }
  }
  
// Utility for prefixing mixins
@mixin prefixer($property, $value, $prefixes: webkit moz) {
    $valid: false;
    @if type-of($property) == "string" {
        $valid: true;
    }
    @if type-of($prefixes) == "list" {
        $valid: true;
    }
    @if type-of($prefixes) == "string" {
        $valid: true;
    }
    @if $valid == true {
        @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
        }
        #{$property}: $value;
    } @else {
        @warn "Invalid arguments supplied";
    }
}