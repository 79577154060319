.ablauf-subheadline{
   
    margin-bottom: 0;
    margin-top:1.5rem;
    padding:15px 0;
    background-color: #e4e3e3;
}
.fk-ablauf{
    .ablauf-subheadline{ color: $green-mid; }
}
.starter-ablauf{
    @media only screen and (max-width: 1450px) {
       
            .starter-triple{
                width: 100%;
            }
            .starter-single{
                width: 100%;
            }
        
    }
   
}
.ablauf-container{
    padding: 0 space(8);
    padding-bottom: space(6);
    padding-top: space(10);
    @include center();
    

    @include breakpoint(large up){
        min-height: 620px;
        max-height: 620px;
    } 
    @include breakpoint(medium){
        min-height: 550px;
        max-height: 550px;
    } 

    

    h2, p{
        color: $white;
    }
   

    &-fk-green{
        &-0{
            background-color:$green-dark;
        }
        &-1{
            background-color:$green-mid;
        }
        &-2{
            background-color:$green;
        }
        &-3{
            background-color:$green-light;
        }
    }

    &-starter-blue{
        &-0{
            background-color:$blue-dark;
        }
        &-1{
            background-color:$blue-mid;
        }
        &-2{
            background-color:$blue;
        }
        &-3{
            background-color:$blue-light;
        }
    }

} 