// ###Reset

// Mixin Reset
@mixin reset(){

    // Reset all browser styles & set font-size to 100%
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
  
    // Set HTML5 elements to block
    article, aside, details, figcaption, figure,
    footer, header, hgroup, main, menu, nav, section {
      display: block;
    }
  
    // Remove built in list styling
    ol, ul {
      list-style: none;
    }
  
    // Remove link underlining
    a {
      text-decoration: none;
    }
  
    // Normalize block quotes
    blockquote, q {
      quotes: none;
    }
  
    // Remove pseudo elements
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
  
    // Normalize tables
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
  
    // Global border-box
    * {
      @include box-sizing(border-box);
    }
  
  }