body{
    font-family: $font-regular;
    color:$blue;
    font-size: 1.2rem;
    
    p, .check-list li, strong, ul li{
        font-size: 1.2rem;
    }
   
    @include breakpoint(small down){
        p, .check-list li, strong, ul li{
            font-size: 16px;
        }
    }
}
.show-for-medium-down{
    display:none;
    @include breakpoint(medium down){
        display:block;
    }
}
.natty-font{
    color:$natty-green;
}
.natty-bg{
    background-color:$natty-green;
  
}
.blue-bg{
    background-color:$blue-light;
  
}
.grey-bg{
    background-color:$grey;
}
.blue-box{
    background-color:$other-blue;
   
}
.mono{
    font-family: $font-monobold;
}
.bold{
    font-family: $font-bold;
}
h2{
    font-family: $font-monobold;
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom:space(5);
    margin-top:space(5);
    @include breakpoint(small down){
        font-size: 2rem;
    }
}
h3{
    font-family: $font-monobold;
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 35px;
    @include breakpoint(medium down){
        font-size: 32px;
    }
    @include breakpoint(small down){
        font-size: 24px;
    }
}

.grid-container{
   
    @include breakpoint(medium down){
        padding-right: 3.75rem;
        padding-left: 3.75rem;
        // &.fluid, &.full{
        //     padding-right: 3.75rem;
        //     padding-left: 3.75rem;
        // }
    }
    @include breakpoint(small down){
        padding-right: 0.975rem;
        padding-left: 0.975rem;
        // &.fluid, &.full{
        //     padding-right: 0.975rem;
        //     padding-left: 0.975rem;
        // }
    }
}
.grey{
    background-color: $grey;
}
section{
    background-color: white;
    padding:80px 0;
    @include breakpoint(medium down){
        padding:40px 0;
    }

}
.no-padding{
    padding: 0;
}
.no-padding-top{
    padding-top: 0;
}
.no-padding-bottom{
    padding-bottom: 0;
}
.ihk-image{
    //border:2px solid white;
}
.sponsor-list img{
    margin:.5rem 0;
}



#intro{
    h1{
        font-size: 78px;
        text-transform: uppercase;
        font-family: $font-monobold;
        letter-spacing: 3px;
        line-height: 0.9;
        margin-bottom: 40px;
        @include breakpoint(small down){
            font-size: 64px;
        }
    } 
}

#editorial{
    h1{
        font-family: $font-monobold;
        text-transform: uppercase;
    }
    p{
        font-family: $font-mono;
    }
}
#ablauf{
    @include breakpoint(medium down){
        &.no-padding{
            padding: 80px 0;
            padding-top:0;
        }
    }
    .grid-container{
        @include breakpoint(large up){
            padding-right: 0;
        }
        @include breakpoint(medium down){
            .no-padding{
                padding: 80px 0;
                padding-top:0;
            }
        }
    }
    h2{
        margin-top:80px;
        font-size: 52px;
       
        @include breakpoint(1024px){
            font-size: 36px;
        }
        @include breakpoint(small down){
            font-size: 33px;
        }
    }
}
.blue-1{
    background-color: #0E19A9;
    @include breakpoint(medium down){
       margin-top:60px;
    }
}
.blue-2{
    background-color: #2E3BDB;
}
.blue-3{
    background-color: #505BEF;
}
.blue-4{
    background-color: #6670FC;
}
.blue-1, .blue-2, .blue-3, .blue-4{
    padding:60px 90px 60px 220px;
    position: relative;
    min-height: 240px;
    @include breakpoint(small down){
        min-height: auto;
        padding:25px;
        width: 100%;
    }
    .circle-container{  
        @include breakpoint(small down){
           display:none;
        }
        .outer-circle{
            position: absolute;
            background-color:$dark-grey;
            border-radius: 100%;
            border: 5px solid #FFFFFF;
            width: 60px;
            height: 60px;
            left: 120px;
            top: 50px;
            .inner-circle{
                position: absolute;
                background-color:$dark-grey;
                border-radius: 100%;
                border: 5px solid #FFFFFF;
                width: 24px;
                height: 24px;
                left: 50%;
                top: 25%;
                transform: translate(-50%);
            }
        }
        
        .vertical-line {
            height: calc(100% - 3.5rem);
            width: 1px;
            position: absolute;
            left: 149px;
            bottom: -50px;
            border-left: 3px solid #fff;
            z-index: 7;
        }
    }
    h3{
        color:white;
        margin: 0;
    }
    p{
        color:white;
    }
}

.check-list{
    li{
        list-style-type: none;
        position: relative;
        font-size: 22px;
        @include breakpoint(small down){
            font-size: 18px;
        }
        &:before{
            content:' ';
            background-image: url(../../htdocs/img/assets/haken.png);
            width: 20px;
            height:20px;
            position: absolute;
            left:-25px;
            background-size: contain;
            background-repeat: no-repeat;
            top: 9px;
        }
    }
    
    
}
#eichelmann{
    
    .potrait{
        display: block;
        @include breakpoint(medium down){
            text-align: center;
            width: 280px;
            margin:0 auto;
            margin-top:35px;
        }
    }
}
#vorteile{
    .check-list{
        // margin-bottom: 120px;
        @include breakpoint(medium down){
            margin-bottom: 10px;
            margin-left: 35px;
        }
    }
    img{
        margin-top: 100px;
        margin-bottom: 130px;
        &.video-teaser{
            margin: 0;
        }
    }

    h3{
        margin-bottom: 60px;
    }
    strong{
        font-family: $font-mono;
    } 
}

#zusatzleistungen{
    h2{
        margin-bottom:25px;
        &.price{
            margin-bottom: 0;
            font-size: 54px;
            text-transform: none;
            @include breakpoint(small down){
                font-size: 28px;
            
            }
        }
    }
    hr{
        margin-bottom: 50px;
        border: 4px solid $king-blue;
    }
    strong{
        position: relative;
        &:before{
            content:' ';
            background-image: url(../../htdocs/img/assets/haken.png);
            width: 20px;
            height:20px;
            position: absolute;
            left:-30px;
            background-size: contain;
            background-repeat: no-repeat;
            top: 5px;
            @include breakpoint(medium down){
               background-image: none;
            }
        }
    }
}
.link-box{
    text-transform: uppercase;
    font-family: $font-monobold;
    border-top: 2px solid $king-blue;
    border-left: 2px solid $king-blue;
    border-bottom: 2px solid $king-blue;
    color:$king-blue;
    padding:10px;
    position: relative;
    margin-top: 35px;
    margin-top: 35px;
    display: inline-block;
    min-width: 220px;

    
    @include breakpoint(medium down){
        min-width: 150px;
        font-size: 14px;
        
    }
  
    &:after{
        position: absolute;
        content: ' ';
        background-image: url("../../htdocs/img/assets/ios-arrow-thin-right.svg");
        background-size: 50px;
        background-repeat: no-repeat;
        background-position: center;
        color:$king-blue;
        font-family: $font-mono;
        border:2px solid $king-blue;
        padding: 14px;
        right: -120px;
        width: 120px;
        height: calc( 100% + 4px);
        top: -2px;
    }
    &:hover{
        color:$king-blue;
        &:after{
            transition: all ease-in-out 0.2s;
            background-color:$king-blue;
            background-image: url("../../htdocs/img/assets/ios-arrow-thin-right_white.svg");
        }
    }
    &.white{
        color:white;
        border-top: 2px solid white;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        min-width: 120px;
        &:after{
            border:2px solid white;
            background-image: url(../../htdocs/img/assets/ios-arrow-thin-right_white.svg);
        }

        &:hover{
            &:after{
                color:$king-blue;
                transition: all ease-in-out 0.2s;
                background-color:white;
                background-image: url(../../htdocs/img/assets/ios-arrow-thin-right.svg);
            }
        }
        
    }
}
footer{
    background-color: $king-blue;
    color:white;
    padding:90px 0;
    img{
        max-width: 420px;
        width: 100%;
    }
    .ihk-image{
        display: block;
        margin-top:10px;
        border: 3px solid white;
     }
    h3{
        margin-bottom: 0px;
    }
    h4{
        font-family: $font-mono;
        font-size: 20px;
        margin-bottom: 76px;
    }
    a{
        color:white;
    }
    p{
        font-size: 16px;
    }
    .sponsor-claim{
        margin-top: 30px;
        margin-bottom: 0;
    }
    hr{
        width:96%;
        margin: 2rem auto;
        max-width: 100%;
    }
    ul{
        li{
            a{
                font-family: $font-monobold;
                text-transform: uppercase;
                font-size: 18px;
            }
            margin-bottom:30px
        }
    }
    @include breakpoint(small down){
        .kontakt-wrapper{
            margin-top:30px;
        }
    }

}


.spacer{
    border:none;
    height: 1px;
    margin: space(68) 0;
}


