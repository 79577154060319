@function space($num){
    @if type-of($num) != "number" {
        @warn "num: #{$num} is not a number";
        @return null;
    }
    @return #{$num * 0.25}rem;
}
@mixin break{
    flex-basis: 100%;
    height: 0;
}