.register-form,
.contact-form{
    form{
        input,
        textarea{
            border: 2px solid $blue;
            color: $blue;
            background-color: $white;
            font-family: $font-mono;
        }
        textarea{
            min-height: 200px;
        }
    }
}

.cta-button-wrapper{    
    margin: auto 0;
    padding: 10px 25px;
    cursor: pointer;

    &.fk{
        background-color:$green;

        &:hover{
            background-color: $green-dark;
            
        }

    }
    &.starter{
        background-color:$blue;
        &:hover{
            background-color: $blue-dark;
            
        }
    }
    label{
        font-family: $font-monobold;
        font-size: 3rem;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        @include breakpoint(small down){
            font-size: 1rem;
        }
    }

}

label{
    font-family: $font-monobold;
    color:$blue;
    &.inline{
        display: inline-block;
    }
}

.message{
    padding: 15px;
    color: white;
    margin: space(30) 0 space(10) 0;

    &.success{
        background-color: $success-green;
    }

    &.error{
        background-color: $red;
    }
    + form{
       margin-top: 0; 
    }
}
.button,
input[type="submit"]{
    text-transform: uppercase;
    font-family: $font-monobold;
    border: 2px solid $blue;
    color: $blue;
    padding:10px 20px;
    position: relative;
    margin-top: 35px;
    margin-top: 35px;
    display: inline-block;
    min-width: 220px;
    background-color: $white;
    
    @include breakpoint(medium down){
        min-width: 150px;
        font-size: 14px;
        
    }
}

.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 5px;
    padding-top: 0;
    cursor: pointer;
    font-size: 16px;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 2px solid #0e19a9;
    border-radius: 0px;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #ffffff;
}

.control input:checked ~ .control_indicator {
    background: #ffffff;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #ffffff;
}

.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}

.control input:checked ~ .control_indicator:after {
    display: block;
}

.control-checkbox .control_indicator:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid #0e19a9;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -0.7rem;
    margin-top: -0.9rem;
    background: $blue;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}