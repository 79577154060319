.stoerer{
    background-color: $natty-green;
    position: absolute;
    top:-120px;
    color:white;
    padding:0 20px 20px 20px;
    border-radius: 100%;
    padding: 1.5rem;
    height: 200px;
    width: 200px;
    right: -40px;
    text-align: center;    
    z-index: 1;
    h3{
        margin-bottom: 1rem;
        font-size: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    p{
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 20px;
    }
    // .sk{
    //     max-width: 50px;
    // }

    @include breakpoint(1024px down){
        top:-80px;
        font-size: 18px;
        height: 160px;
        width: 160px;
        left:initial;
        right: 0;
        transform: translate(0);
        img{
            width:190px;
            display: block;
        }
    }

    @include breakpoint(small down){
 
       
       
        padding: 0;
        .show-for-small-only{
            h3{
                margin-top:3rem;
                font-size: 16px;
            }
        }
    }

}