.angebote{

    h2{
        color:$blue;    
    }

    color:white;

    .grid-container{
        max-width: 87.5rem;
    }

    hr{
        border: 4px solid #FFFFFF;
    }
    .logo-container{
        margin-top: space(10);

        .plus{
            font-family: $font-monobold;
            text-transform: uppercase;
            font-size: 3rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            display: block;
        }

    }
    .price-wrapper{
        box-shadow: inset 0px 1px 3px 0px rgba(54, 54, 54, 0.795);
        background-color:$green-light;

        @include breakpoint(large up){
            position: absolute;
            bottom: -30px;            
        }
        @include breakpoint(medium down){
            box-shadow: none;
        }   
        padding: space(4) 0 !important;
        h2{
            margin-top: 0;
        }
        &.new2022{
            padding:1rem !important;
        }
    }

    .angebot-pillar{
        h2{
            color:$white;    
        }
        position: relative;           
        padding-bottom: 100px !important;

        @include breakpoint(medium down){
            margin-bottom: 3rem;
            padding-bottom: inherit !important;
        }   
        
        &[class*="-premium"]{
            .cell{
                padding-top: 8px;
            }
        }
    }

    .angebot-basic-starter{
        box-shadow: inset -2px 0px 5px 0px rgba(54, 54, 54, 0.795);
        background-color: $blue-light;       
        margin-bottom: 65px;

        .price-wrapper{
            background-color:$blue-mid;
            box-shadow: inset -2px 2px 5px 0px rgba(54, 54, 54, 0.795);
            @include breakpoint(medium down){
                box-shadow: none;
            }   
        }    

        @include breakpoint(medium down){
            box-shadow: none;
        }   
    }

    .angebot-premium-starter{
        background-color: $blue-light;
       
        @include breakpoint(large up){
           // padding-bottom: 35px !important;
        }        
        @include breakpoint(medium down){
            margin-bottom: 3rem;
        } 

        .price-wrapper{
            background-color:$blue-mid;
            @include breakpoint(medium down){
                box-shadow: none;
            }   
        }     
    }
    .angebot-advance-starter{
        background-color: $blue-light;
        box-shadow: inset 2px 0px 5px 0px rgba(54, 54, 54, 0.795);
        margin-bottom: 65px;

        @include breakpoint(medium down){
            margin-bottom: 3rem;
            box-shadow: none;
        } 

        .price-wrapper{
            box-shadow: inset 2px 2px 5px 0px rgba(54, 54, 54, 0.795);
            background-color:$blue-mid;

            @include breakpoint(medium down){
                box-shadow: none;
            }   
        }     

        @include breakpoint(medium down){
            box-shadow: none;
        }   
    }


    .angebot-basic-fk{
        background-color: $green-mid;       
        box-shadow: inset -2px 0px 5px 0px rgba(54, 54, 54, 0.795);
        margin-bottom: 65px;

        @include breakpoint(medium down){
            margin-bottom: 3rem;
            box-shadow: none;
        }    
        
    }

    .angebot-premium-fk{
        background-color: $green;
       
        @include breakpoint(large up){
            padding-bottom: 100px !important;
        }        
        @include breakpoint(medium down){
            margin-bottom: 3rem;
            box-shadow: none;
        } 
        
    }

    .angebot-advance-fk{
        background-color: $green-mid;
        box-shadow: inset 2px 0px 5px 0px rgba(54, 54, 54, 0.795);
        margin-bottom: 65px;
        @include breakpoint(medium down){
            margin-bottom: 3rem;
            box-shadow: none;            
        } 
        .price-wrapper{
            box-shadow: inset 2px 2px 5px 0px rgba(54, 54, 54, 0.795);

            @include breakpoint(medium down){            
                box-shadow: none;            
            } 
        }     
    }

    
    .cell{
        padding:40px 35px;
        &.no-padding{
            padding: initial;
        }
        &.natty-bg{
            box-shadow: 0px 3px 6px #00000029;
            &.no-padding{
                padding:0 60px;         
            }
        }
        @include breakpoint(medium down){
            // padding:40px 60px !important;
        }
        @include breakpoint(small down){
            &.blue-box{
                padding:25px !important;
            }
        }
    }    
    h2{
        margin-bottom: 25px;
        &.price{                     
            margin-bottom: 0;           
            text-transform: none;
           
            @include breakpoint(medium down){
                font-size: 48px;
                margin-top:0px;
            }
        }
    }
    h3{
        text-transform: initial;
    }
    p{
        font-size: 20px;
    }
    .disclaimer{
        color: $blue;
        font-family: $font-mono;
        display: block;
        text-align: center;
    }
}