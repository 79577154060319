#countdown{
    position: absolute;
    top: calc(45% + 260px);
    left:80px;      
    padding:12px;

    &.fk{
        background-color:$green;
    }
    &.starter{
        background-color:$blue;
    }

    @include breakpoint(medium down){
        top: 70%;
        left:50%;
        transform: translateX(-50%);
        width: fit-content;
        padding: 0 3rem;
    } 
    @include breakpoint(small down){
        top: calc(66%);
        background-position: 100%;
    }
    label{               
        float:left;
        margin-right: space(8);                
        margin-bottom: 0;
        text-align: center;
        color: $white;

        span{
            display: block;
            font-size: 3rem;
            line-height: 1;

            @include breakpoint(small down){
                font-size: 1.6rem;
            }
        }
        @include breakpoint(small down){
            margin-right: space(4);   
        }
                
        &:last-child{
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 320px) {
        display: none;
    }
    
}