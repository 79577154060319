@font-face {
    font-family: 'Roboto';
    src: url('./../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Mono';
    src: url('./../fonts/Roboto_Mono/static/RobotoMono-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('./../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Mono-Bold';
    src: url('./../fonts/Roboto_Mono/static/RobotoMono-Bold.ttf') format('truetype');
}