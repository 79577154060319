.statement{
    h3{
        margin-bottom: 0;
    }
    p{
        font-family: $font-mono;
        font-size: 1.1rem;
    }
    hr{
        margin:15px 0;
        border-bottom: 3px solid $blue;
    }
    &-numberwrapper{
        margin-bottom: space(30);
    }
    &-number{
        span{
            font-size: 5rem;
            color: $green;
        }
    }
    &-textcontainer{
        padding: 0 20px;
        position: relative;

        small{
            font-family: $font-mono;
            font-size: 0.8rem;
            display: block;
        }

        @include breakpoint(medium down){
            padding: 0;
            padding-bottom: 70px;
        }
    }
    &-zitat{
        position: absolute;
        right: 0;
        bottom: 0;
        color: $blue;
        font-size: 10rem;
        line-height: 0;

        &-bottom{
            line-height: 1;
            height: 60px;
            bottom: -60px;
        }
    }
}