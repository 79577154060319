@mixin vertical-center(){
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    width: 100%;
}
@mixin horizontal-center(){
    position: absolute;
    transform: translateX(-50%);
    left:50%;
}
@mixin center(){
    text-align: center;
}
.center{
    text-align: center;
}
.vertical-wrapper{
    height: 100%;
    position: relative;
    > *{
        @include breakpoint(large up){
            @include vertical-center();
        } 
    }
}
.break{
    @include break();
}