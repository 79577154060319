.auswahl{
    margin: 0;
    padding: 0;

    .full-image{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: bottom;
        margin-bottom: 45vh;
        position: relative;

        &.starter{
            background-image: url('../../htdocs/img/content/s_bild_start.jpg');
        }
        &.fk{
            background-image: url('../../htdocs/img/content/fk_bild_start.jpg');
            background-position: inherit;
        }
        .logo-zk{
            max-width: 780px;
            width: 100%;
            padding: 40px 80px;
            @include breakpoint(medium down){
                max-width: 640px;
                padding:40px 20px;
                width: calc(100% - 40px);
            } 
        }
        .text-container{
            padding:0 80px;
            max-width: 780px;
            width: 100%;

            @include breakpoint(medium down){
                max-width: 100%;
                padding:0;
            } 
        }
        .logo-container{
            img{
                position: absolute;
                max-width: 350px;
                height: 230px;
                width: 100%;
                bottom: 40px;

                @include breakpoint(small){
                    max-width: 280px;
                } 
            }
        }
        .logo-zk-starter{
            left: 80px;

            @include breakpoint(small down){
                left: 20px;
            } 
        }
        .logo-zk-fk{
           right: 80px;

            @include breakpoint(small down){
                right: initial;
                left:20px;
            } 
        }
        @include breakpoint(small down){
            .cell{
                padding-right: .975rem;
                padding-left: .975rem;
                width:calc(100% - 56px);
            }
        } 
    }
}
.messe-logos{
    @include breakpoint(small){
        h2{
            word-break: break-word;
        }
    } 
}