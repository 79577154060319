.timetable{
    &-item{
        width: 100%;
        background-color: $white;
        font-family: $font-mono;
        padding:10px 15px;
        border:3px solid $blue;

        &-meta{
            display: block;
            overflow: hidden;
            width: 100%;

            label{
                background-color: $green;
                color: $white;
                font-size: 0.7rem;
                padding:2px 10px;
                display: inline-block;
                float:left;
                margin-right: 12px;
                margin-bottom: 12px;
            }
        }

        &-info{
            h3{
                font-size: 1.2rem;
                margin-bottom: 0.2rem;
            }
            p{
                font-size: 0.9rem;
                margin-bottom: 0;
            }
        }
    }
}