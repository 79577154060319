#aussteller{
    img{
        height: auto;
        width: auto;
        max-height: 80px;
    }
    p{
        font-size: 1rem;
    }

    a{
        font-family: $font-mono;
        color: $blue;
    }
}