.recruiting-video{
    &__container {
        background-image: url('../../htdocs/img/content/iPad_Recruiting_Video.png');
        background-repeat: no-repeat;
        background-size: 800px;
        background-position-x: right;
        min-height: 800px;
        padding-top: 250px;

        @include breakpoint(medium down){
            background-size: 310px;
        }    
    }
    
    &__offer {
        background-color: $blue-light;
        max-width: 420px;
        width: 100%;
        margin-left: 10%;
        position: relative;

        @include breakpoint(medium down){
            margin-left: 0;
            max-width: 100%;
        }   
       
        h2{
            color: $white;
            margin-bottom: 25px;
        }        
        h3, p, li, label{
            color: $white;
        }
        hr {
            border: 4px solid $white;
        }
        &--content{
            padding: 25px;
        }
        &--price{
         
            background-color: $blue-mid;
            text-align: center;
            h2{
                display: inline-block;
            }
            label{
                font-size: 1.8rem;
                display: inline-block;
                margin-right: 12px;
            }
        }
        &--qr{            
            position: absolute;
            bottom: 20px;
            z-index: 1;
            background-color: $white;
            right: -160px;
            padding: 25px;
            width: 160px;
            @include shadow();

            label{
                color: $king-blue;   
            }
            
            @include breakpoint(medium down){
                position: initial;
                left: 0;
                right: initial;
                width: 100%;
                text-align: center;

                img{                   
                    width: 120px;
                }
            }   
            
        }
    }


}