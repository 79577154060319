@import 'foundation-sites/scss/foundation.scss';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;

// Basics
@include foundation-tooltip;

// Layout components
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-visibility-classes;
@include foundation-flex-classes;







.grid-container{
    max-width: 77.5rem;
    
    &.no-padding{
        padding:0;
    }
    @include breakpoint(medium down){
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
.grid-x{
    &.equal-height{
        height: 100%;
    }
}
.hidden{
    display:none;
}